import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/course/course-dot.png'
import _imports_1 from '@/assets/img/course/payment/payment-1.png'
import _imports_2 from '@/assets/img/course/payment/stripe.png'


const _hoisted_1 = { class: "col-xxl-4 col-xl-4 col-lg-4" }
const _hoisted_2 = { class: "course__sidebar pl-70 p-relative" }
const _hoisted_3 = { class: "course__sidebar-widget-2 white-bg mb-20" }
const _hoisted_4 = { class: "course__video" }
const _hoisted_5 = { class: "course__video-thumb w-img mb-25" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "course__video-play" }
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 0,
  class: "course__enroll-btn mb-3"
}
const _hoisted_10 = {
  class: "mt-3",
  style: {"display":"flex","justify-content":"center"}
}
const _hoisted_11 = {
  style: {"color":"blue","font-weight":"bold"},
  class: "uppercase"
}
const _hoisted_12 = {
  key: 1,
  class: "course__video-meta mb-25 d-flex align-items-center justify-content-between"
}
const _hoisted_13 = { class: "course__video-price" }
const _hoisted_14 = { class: "old-price" }
const _hoisted_15 = { class: "course__video-discount" }
const _hoisted_16 = {
  key: 2,
  class: "mb-25 d-flex align-items-center justify-content-center"
}
const _hoisted_17 = { class: "text-success text-uppercase" }
const _hoisted_18 = { class: "course__video-content mb-35" }
const _hoisted_19 = { class: "d-flex align-items-center" }
const _hoisted_20 = { class: "course__video-info" }
const _hoisted_21 = { class: "d-flex align-items-center" }
const _hoisted_22 = { class: "course__video-info" }
const _hoisted_23 = {
  key: 3,
  class: "course__payment mb-35"
}
const _hoisted_24 = { class: "video-modal-wrapper" }
const _hoisted_25 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!
  const _directive_b_modal = _resolveDirective("b-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "course__shape" }, [
          _createElementVNode("img", {
            class: "course-dot",
            src: _imports_0,
            alt: ""
          })
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: _ctx.imageFileLink,
                alt: ""
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives((_openBlock(), _createElementBlock("button", {
                  href: _ctx.imageFileLink,
                  "data-fancybox": "",
                  class: "play-btn"
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("i", { class: "fas fa-play" }, null, -1)
                ]), 8, _hoisted_8)), [
                  [
                    _directive_b_modal,
                    void 0,
                    void 0,
                    { "modal-center": true }
                  ]
                ])
              ])
            ]),
            (_ctx.showBuyButton)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("button", {
                    class: "e-btn e-btn-7 w-100",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args)))
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cart.buyNow')) + " ", 1),
                    _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-solid fa-basket-shopping" }, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('cart.buyNow')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.course?.priceUSD > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("h5", null, "$ " + _toDisplayString(_ctx.course?.priceUSD?.toFixed(2)), 1),
                    _createElementVNode("h5", _hoisted_14, _toDisplayString(_ctx.course?.priceFull?.toFixed(2)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.discountPercentage) + "% OFF", 1)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("h2", _hoisted_17, _toDisplayString(_ctx.$t('cart.free')), 1)
                ])),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("ul", null, [
                _createElementVNode("li", _hoisted_19, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "course__video-icon" }, [
                    _createElementVNode("svg", null, [
                      _createElementVNode("path", {
                        class: "st0",
                        d: "M13.3,14v-1.3c0-1.5-1.2-2.7-2.7-2.7H5.3c-1.5,0-2.7,1.2-2.7,2.7V14"
                      }),
                      _createElementVNode("circle", {
                        class: "st0",
                        cx: "8",
                        cy: "4.7",
                        r: "2.7"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("h5", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", null, "Infoproductor :", -1)),
                      _createTextVNode(_toDisplayString(_ctx.course.teacherName), 1)
                    ])
                  ])
                ]),
                _createElementVNode("li", _hoisted_21, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "course__video-icon" }, [
                    _createElementVNode("svg", null, [
                      _createElementVNode("circle", {
                        class: "st0",
                        cx: "8",
                        cy: "8",
                        r: "6.7"
                      }),
                      _createElementVNode("line", {
                        class: "st0",
                        x1: "1.3",
                        y1: "8",
                        x2: "14.7",
                        y2: "8"
                      }),
                      _createElementVNode("path", {
                        class: "st0",
                        d: "M8,1.3c1.7,1.8,2.6,4.2,2.7,6.7c-0.1,2.5-1,4.8-2.7,6.7C6.3,12.8,5.4,10.5,5.3,8C5.4,5.5,6.3,3.2,8,1.3z"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("h5", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('course.language')) + " :", 1),
                      _createTextVNode(_toDisplayString(_ctx.course?.defaultLanguage ==='es'? 'English': 'Spanish'), 1)
                    ])
                  ])
                ])
              ])
            ]),
            (_ctx.showBuyButton)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Payment:", -1)),
                  _createElementVNode("a", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args)))
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: ""
                    }, null, -1),
                    _createElementVNode("img", {
                      class: "ml-5 w-25",
                      src: _imports_2,
                      alt: ""
                    }, null, -1)
                  ]))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_b_modal, {
          id: "modal-center",
          centered: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, [
              _createElementVNode("iframe", {
                src: _ctx.coverVideoFileLink,
                title: "YouTube video player",
                frameborder: "0",
                allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: ""
              }, null, 8, _hoisted_25)
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}