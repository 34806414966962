<template>
  <section class="events__area mt-30">
    <div class="section2">
      <div class="section-inner">
        <div class="frame-parent24">
          <div class="heading-2-unmatched-features-parent">
            <div class="heading-22">
              <span class="section__title text-white" >
                {{ $t('home.becomeInstructor.title.frag1') }}
                <span class="yellow-bg yellow-bg-big"> {{ $t('home.becomeInstructor.title.frag2') }}<img
                    src="@/assets/img/shape/yellow-bg.png" alt=""></span> {{ $t('home.becomeInstructor.title.frag3') }}
              </span>
            </div>
          </div>
          <div class="subtitle">
            <div class="subtitle_content">
              {{ $t('home.becomeInstructor.subtitle') }}
            </div>
          </div>
        </div>
      </div>

      <!-- Primera sección -->
      <div class="content">
        <!-- División izquierda con título y subtítulo -->
        <div class="left-content" >
          <div class="heading-2-unmatched-features-parent">
            <div class="heading-22">
              <span class="section__title text-white">
                {{ $t('home.becomeInstructor.section1.title') }}
                <span class="yellow-bg yellow-bg-big"> {{ $t('home.becomeInstructor.section1.subtitle') }}<img
                    src="@/assets/img/shape/yellow-bg.png" alt=""></span>
              </span>
            </div>
          </div>
          <p class="text-white">{{ $t('home.becomeInstructor.section1.body') }}</p>
        </div>
        <!-- División derecha con imagen -->
        <div class="right-content">
          <img src="@/assets/img/Image.png" alt="Imagen">
        </div>
      </div>

      <!-- Segunda sección -->
      <div class="content" >

        <div class="left">
          <img src="@/assets/img/Image1.png" alt="Imagen">
        </div>
        <div class="right-content">
          <div class="heading-2-unmatched-features-parent">
            <div class="heading-22">
              <span class="section__title text-white">
                {{ $t('home.becomeInstructor.section2.title') }}
                <span class="yellow-bg yellow-bg-big"> {{ $t('home.becomeInstructor.section2.subtitle') }}<img
                    src="@/assets/img/shape/yellow-bg.png" alt=""></span>
              </span>
            </div>
          </div>
          <p class="text-white">{{ $t('home.becomeInstructor.section2.body') }}</p>
        </div>
      </div>

      <!-- Tercera sección -->
      <div class="content">
        <!-- División izquierda con título y subtítulo -->
        <div class="left-content">
          <div class="heading-2-unmatched-features-parent">
            <div class="heading-22">
              <span class="section__title text-white">

                <span class="yellow-bg yellow-bg-big"> {{ $t('home.becomeInstructor.section3.title') }}<img
                    src="@/assets/img/shape/yellow-bg.png" alt=""></span>
              </span>
            </div>
          </div>
          <p class="text-white">{{ $t('home.becomeInstructor.section3.body') }}</p>
        </div>
        <!-- División derecha con imagen -->
        <div class="right-content">
          <img src="@/assets/img/Image2.png" alt="Imagen">
        </div>
      </div>

      <div>


        <div class="footer" >
          <img src="@/assets/img/div.link-line.png" alt="" >
          <img src="@/assets/img/div.link-line.png" alt="" style="width: 300px;">
          <h1 class="text-white"> {{ $t('home.becomeInstructor.message') }}</h1>

          <router-link to="/instructor-register" class="e-btn e-btn-border e-btn-5">{{ $t('footer.becomeTeacher') }}
          </router-link>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'EventArea',
};
</script>

<style scoped>
.heading-22 {
  top: 0px;
  left: 0px;
  line-height: 70px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 4rem;
}

.subtitle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  font-size: 23px;
}

.frame-parent24 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 24px;
  max-width: 100%;
}

.section2 {
  flex: 1;
  background: linear-gradient(180deg, #222, #0a1042);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 120px 22px 151px 20px;
  box-sizing: border-box;
  gap: 42.09999999999991px;
  max-width: 100%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  max-width: 800px;
  /* Agregamos un margen inferior entre las secciones */
}

.left-content {
  display: table-column;
  width: 50%;
  text-align: start;
}

.right-content {
  width: 50%;
  text-align: center;
}

.section_title{
  font-size: 2.8rem
}

.footer{
  display: flex; 
  flex-direction: column;  
  align-items: center; 
  text-align: center;

  img{
    width: 200px; 
    margin-bottom: 20px;
  }
}

/* Estilos para dispositivos móviles */
@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .left-content,
  .left
  .right-content {
    width: 100%;
  }

  .right-content img,
  .left img,
  .left-content img {
    max-width: 100%;
    height: auto;
  }
}
</style>