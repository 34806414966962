<template>
  <Header header__white="header__white" />
  <div class="about__area pt-90 pb-150 relative text-center">
    <div class="container relative">
      <div class="row justify-content-center">
        <div class="col-12 col-md-9">
          <div class="about__content">
            <div class="terms__title-wrapper mb-25">
              <h2 class="section__title slide-in w-100 text-center">
                {{ $t('about.legalNotice.legalNotice') }}
              </h2>
              <div class="mt-4 space-y-4 text-gray-600">
                <div>
                  <p>
                    {{ $t('about.legalNotice.legalNoticeDesc') }}
                  </p>
                </div>
                <ul class="list-disc pl-5 space-y-3">
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.legalNotice.companyName') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>Chaturi LLC-FZ</li>
                    </ul>
                  </li>
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.legalNotice.companyAddress') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>Meydan Grandstand, 6th floor, Meydan Road, Nad Al Sheba, Dubai,</li>
                    </ul>
                  </li>
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.legalNotice.contactPhoneNUmber') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>+971555276812</li>
                    </ul>
                  </li>
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.legalNotice.contactE') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>support@bullfy.com</li>
                    </ul>
                  </li>
                </ul>
                <div>
                  <p class="mt-3">{{ $t('about.legalNotice.informationCourses') }}</p>
                  <h3>{{ $t('about.legalNotice.intellectualProperty') }}</h3>
                  <p class="mt-3">{{ $t('about.legalNotice.copyright1') }}</p>
                  <p class="mt-3">{{ $t('about.legalNotice.copyright2') }}</p>
                  <p class="mt-3">{{ $t('about.legalNotice.copyright3') }} support@bullfy.com.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img src="@/assets/img/img2.png" alt="" class="decorative-image" style="position: absolute; top: 50%; left: 10%;">
  <img src="@/assets/img/img2.png" alt="" class="decorative-image" style="position: absolute; top: 100%; right: 10%;">


  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header.vue';

import Footer from '../components/Home/Footer.vue';

export default {
  name:'LegalPage',
  components:{
    Header,
    Footer,
  }
}
</script>

<style scoped>

.cookie-label{
  color: #000;
}
.terms__title-wrapper {
  text-align: left;

}
ul {
  list-style-type: circle !important;
}

</style>