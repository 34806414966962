<template>
  <Header header__white="header__white" />
  <div class="about__area pt-90 pb-150 relative text-center">
    <div class="container relative">
      <div class="row justify-content-center">
        <div class="col-12 col-md-9">
          <div class="about__content">
            <div class="terms__title-wrapper mb-25">
              <h2 class="section__title slide-in w-100 text-center">
                {{ $t('about.termsAndConditions.termsAndConditions') }}
              </h2>
              <div class="mt-4 space-y-4 text-gray-600">
                <div>
                  <p>
                    {{ $t('about.termsAndConditions.welcomeToBullfy') }}
                  </p>
                </div>
                <ul class="list-disc pl-5 space-y-3">
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.accountRegistration') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>{{ $t('about.termsAndConditions.toAccessAndPurchase') }}</li>
                      <li>{{ $t('about.termsAndConditions.youAreResponsible') }}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.useOfServices') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>{{ $t('about.termsAndConditions.ourCourses') }}</li>
                      <li>{{ $t('about.termsAndConditions.redistribution') }}</li>
                    </ul>
                  </li>
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.payments') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>{{ $t('about.termsAndConditions.coursePayments') }}</li>
                      <li>
                        {{ $t('about.termsAndConditions.refundsAreIssued') }}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.intellectual') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>{{ $t('about.termsAndConditions.allCourses') }}</li>
                      <li>{{ $t('about.termsAndConditions.youMayNot') }}</li>
                    </ul>
                  </li>
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.limitation') }}</strong>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>{{ $t('about.termsAndConditions.problemsarising') }}</li>
                      <li>{{ $t('about.termsAndConditions.theResultsObtained') }}</li>
                    </ul>
                  </li>
                  <li>
                    <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.changes') }}</strong>
                    <p>
                      {{ $t('about.termsAndConditions.changesDesc') }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img src="@/assets/img/img2.png" alt="" class="decorative-image" style="position: absolute; top: 50%; left: 10%;">
  <img src="@/assets/img/img2.png" alt="" class="decorative-image" style="position: absolute; top: 100%; right: 10%;">


  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header.vue';

import Footer from '../components/Home/Footer.vue';

export default {
    name:'TermsPage',
    components:{
        Header,
        Footer,
    }
}
</script>

<style scoped>

.cookie-label{
  color: #000;
}
.terms__title-wrapper {
  text-align: left;

}
ul {
  list-style-type: circle !important;
}

</style>