<template>
  <Header header__white="header__white" />
  <div class="about__area pt-90 pb-150 relative text-center">
    <div class="container relative">
      <div class="row justify-content-center">
        <div class="col-12 col-md-9">
          <div class="about__content">
            <div class="terms__title-wrapper mb-25">
              <h2 class="section__title slide-in w-100 text-center">
                {{ $t('about.privacyPolicy.privacyPolicy') }}
              </h2>
              <div class="mt-4 space-y-4 text-gray-600">
                <div>
                  <p>
                    {{ $t('about.privacyPolicy.bullfyValues') }}
                  </p>
                </div>
                <ul class="pl-5 my-3">
                  <li>
                    <span class="text-black h5 pb-3">{{ $t('about.privacyPolicy.informationWe') }}</span>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>
                        <span class="text-black font-bold">● {{ $t('about.privacyPolicy.personalInfo') }}</span> {{ $t('about.privacyPolicy.billingInformation') }}
                      </li>
                      <li>
                        <span class="text-black font-bold">● {{ $t('about.privacyPolicy.usageData') }}</span> {{ $t('about.privacyPolicy.deviceInformation') }}
                      </li>
                      <li>
                        <span class="text-black font-bold">● Cookies:</span> {{ $t('about.privacyPolicy.seeOur') }}
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul class="list-disc pl-5 my-3">
                  <li>
                    <span class="text-black h5">{{ $t('about.privacyPolicy.howWeUse') }}</span>
                    <ul class="list-disc pl-5 space-y-1">
                      <li>
                        <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.toProvide') }}
                      </li>
                      <li>
                        <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.toProcess') }}
                      </li>
                      <li>
                        <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.toCommunicate') }}
                      </li>
                      <li>
                        <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.toComply') }}
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul class="list-disc pl-5 my-3">
                  <li>
                    <span class="text-black h5">{{ $t('about.privacyPolicy.sharingYour') }}</span>
                    <ul class="list-disc pl-5 space-y-1">
                      <p> {{ $t('about.privacyPolicy.weDoNot') }}</p>

                      <li>
                        <span class="text-black font-bold">● </span> {{ $t('about.privacyPolicy.payment') }}
                      </li>
                      <li>
                        <span class="text-black font-bold">●</span>  {{ $t('about.privacyPolicy.authorities') }}
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul class="list-disc pl-5 my-3">
                  <li>
                    <span class="text-black h5"> {{ $t('about.privacyPolicy.yourRights') }} </span>
                    <ul class="list-disc pl-5 space-y-1">
                      <p>{{ $t('about.privacyPolicy.dependingOnYour') }} </p>

                      <li>
                        <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.accessAndUpdaste') }}
                      </li>
                      <li>
                        <span class="text-black font-bold">●</span>{{ $t('about.privacyPolicy.requestDeletion') }}
                      </li>
                      <li>
                        <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.withdraw') }}
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul class="list-disc pl-5 my-3">
                  <li>
                    <span class="text-black h5"> {{ $t('about.privacyPolicy.dataSecurity') }}</span>
                    <ul class="list-disc pl-5 space-y-1">
                      <p>{{ $t('about.privacyPolicy.weEmploy') }}</p>
                    </ul>
                  </li>
                </ul>

                <ul class="list-disc pl-5 my-5">
                  <li>
                    <span class="text-black h5">{{ $t('about.privacyPolicy.updates') }}</span>
                    <div class="list-disc pl-5 ">
                      <p>{{ $t('about.privacyPolicy.updatesDesc1') }}</p>
                      <p>{{ $t('about.privacyPolicy.updatesDesc2') }}</p>
                      <p>{{ $t('about.privacyPolicy.updatesDesc3') }} support@bullfy.com.</p>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img src="@/assets/img/img2.png" alt="" class="decorative-image" style="position: absolute; top: 50%; left: 10%;">
  <img src="@/assets/img/img2.png" alt="" class="decorative-image" style="position: absolute; top: 100%; right: 10%;">


  <Footer footerPadding="true" />
</template>

<script>
import Header from '../components/Home/Header.vue';

import Footer from '../components/Home/Footer.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name:'PoliticsPage',
  components:{
    Header,
    Footer,
  }
});
</script>

<style scoped>

.text-body-emphasis{
  font-weight: 800;
}
.terms__title-wrapper {
  text-align: left;

}

</style>