<template>
  <div
      class="page__title--area page__title-height page__title-overlay page__title-wrapper d-flex align-items-center instructor-banner">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <div class="page__title-wrapper">
            <h3 class="page__title">{{ $t('signUp.instructor.title') }}</h3>
            <!--            TODO: bREADCRUMS HERE-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-area">
    <div class="container form-max-width">
      <div class="row">
        <div class="col-12">
          <div class="sign__wrapper white-bg">
            <form class="d-flex flex-column gap-2">
              <div class="sign__input-wrapper">
                <span>&nbsp;{{ $t('signUp.firstName') }} <i class="red">*</i> </span>
                <div class="sign__input">
                  <input v-model="form.firstName" :placeholder="$t('signUp.yourName')" type="text" required>
                </div>
              </div>
              <div class="sign__input-wrapper">
                <span>&nbsp;{{ $t('signUp.lastName') }} <i class="red">*</i></span>
                <div class="sign__input">
                  <input v-model="form.lastName" :placeholder="$t('signUp.lastNamePlaceHolder')" type="text" required>
                </div>
              </div>

              <div class="sign__input-wrapper">
                <span>&nbsp;{{ $t('signUp.email') }} <i class="red">*</i></span>
                <div class="sign__input">
                  <input v-model="form.email" :placeholder="$t('signUp.yourEmail')" type="email" @input="setUsername"
                         required>
                </div>
              </div>
              <div class="sign__input-wrapper">
                <span>&nbsp;{{ $t('signUp.password') }} <i class="red">*</i></span>
                <div class="sign__input">
                  <input v-model="form.password" :placeholder="$t('signUp.yourPassword')" type="password" required>
                </div>
              </div>
              <div class="sign__input-wrapper">
                <span>&nbsp;{{ $t('signUp.repeatPassword') }} <i class="red">*</i></span>
                <div class="sign__input">
                  <input v-model="form.repeatPassword" :placeholder="$t('signUp.yourPassword')" type="password"
                         required>
                </div>
              </div>
            </form>
            <h3 class="mt-20">{{ $t('signUp.authorInfo') }}</h3>
            <form class="d-flex flex-column gap-2">
              <div class="sign__input-wrapper">
                <span>&nbsp;Facebook</span>
                <div class="sign__input">
                  <input v-model="form.facebook" :placeholder="$t('signUp.urlPlaceHolder')" type="url">
                </div>
              </div>

              <div class="sign__input-wrapper">
                <span>&nbsp;Instagram</span>
                <div class="sign__input">
                  <input v-model="form.instagram" :placeholder="$t('signUp.urlPlaceHolder')" type="url">
                </div>
              </div>

            </form>
            <div class="sign__action d-flex justify-content-between mb-30">
              <div class="sign__agree d-flex align-items-center">
                <input class="m-check-input" type="checkbox" id="m-agree" v-model="termsAccepted" required>
                <label class="m-check-label" for="m-agree">{{ $t('signUp.accept') }}
                  <router-link to="terms">{{ $t('signUp.terms') }}</router-link>
                </label>
              </div>
            </div>
            <button class="e-btn w-100"
                    type="button"
                    :disabled="disableButton"
                @click="register">
              {{ $t('signUp.register') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <b-modal id="modal-signup-otp" centered v-model="isModalOTPVisible">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-body">
              <h3>{{ $t('signUp.otpTitle') }}</h3>
              <div class="contact__form">
                <form>
                  <div class="row">
                    <div class="col-xxl-12">
                      <span>&nbsp;{{ $t('signUp.otp') }} <i class="red">*</i></span>
                      <div class="contact__form-input">
                        <input type="text" name="title" v-model="otp">
                      </div>
                    </div>
                    <p>{{ $t('signUp.otpText') }}</p>

                    <div class="col-xxl-12">
                      <div class="contact__btn">
                        <button type="button" @click="confirmOTP" class="e-btn" :disabled="disableConfirmButton">
                          {{ $t('signUp.confirmOTP') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {AuthUseCase} from '@/core/usecases/auth.usecase';
import {useStore} from 'vuex';
import {key} from '@/infrastructure/di/store';
import {useToast} from 'vue-toastification';
// import {BBreadcrumb, BBreadcrumbItem} from 'bootstrap-vue-3';


export default defineComponent({
  name: 'RegisterForm',
  setup() {
    const store = useStore(key);
    return {
      store
    }
  },
  data() {
    return {
      userId: '',
      fullName: '',
      form: {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        youtubeUrl: '',
        xUrl: '',
        linkedInUrl: '',
        instagram: '',
        facebook: ''
      },
      otp: '',
      showPassword: false,
      termsAccepted: false,
      disableConfirmButton: false,
      disableButton: false,
      isModalOTPVisible: false,
      toast: useToast()
    }
  },
  methods: {
    setUsername() {
      this.form.username = this.form.email.split('@')[0];
    },
    async register() {
      // const urls = [this.form.xUrl, this.form.facebook, this.form.instagram, this.form.youtubeUrl, this.form.linkedInUrl];
      if (!this.validateEmail(this.form.email)) {
        this.toast.warning(this.$t('signUp.notValidEmail'));
      } else if (this.form.password !== this.form.repeatPassword) {
        this.toast.warning(this.$t('signUp.notMatch'));
      } else if (!this.validatePassword(this.form.password)) {
        this.toast.warning(this.$t('signUp.notValidPassword'));
        /* } else if (urls.some(u => !this.validateUrl(u))) {
        this.toast.warning(this.$t('signUp.invalidUrl'));
      } */
      } else if (!this.termsAccepted) {
        this.toast.warning(this.$t('signUp.acceptTerms'));
      } else {
        this.fullName = `${this.form.firstName} ${this.form.lastName}`
        const useCase = new AuthUseCase(this.store);
        try {
          this.disableButton = true;
          const res = await useCase.registerInstructor(this.form);

          if (res && res.userId) {
            this.isModalOTPVisible = true;
            this.userId = res.userId;
            this.toast.success(this.$t('signUp.success'));
          } else {
            this.toast.error(this.$t('signUp.error'));
          }
        } catch (error: any) {
          console.log(error)
          if(error?.data && error?.data?.message === 'USER_ALREADY_EXISTS_ERROR'){
            this.toast.error(this.$t('signUp.mailAlreadyRegistered'));
          }else {
            this.toast.error(this.$t('signUp.error'));
          }
        }
        this.disableButton = false;
      }
    },
    async confirmOTP(){
      this.disableConfirmButton = true;
      if(!this.otp || this.otp.length !== 6){
        this.toast.error(this.$t('signUp.otpText'));
        this.disableConfirmButton = false;
        return;
      }
      try {
        const useCase = new AuthUseCase(this.store);
        const res = await useCase.validateOTP(this.userId, this.form.email, this.otp);
        console.log(res);
        if (res && res.success) {
          this.isModalOTPVisible = false;
          this.toast.success(this.$t('signUp.success'));
          AuthUseCase.saveAuthInfo(res.accessToken, res.refreshToken,this.fullName, this.form.email);
          await this.$router.push('/courses');
        }else{
          this.toast.error(this.$t('signUp.otpText'));
        }
      } catch (error: any) {
        console.log(error);
        if(error?.data && error?.data?.message === 'USER_ALREADY_EXISTS_ERROR'){
          this.toast.error(this.$t('signUp.mailAlreadyRegistered'));
        }else {
          this.toast.error(this.$t('signUp.error'));
        }
      }
      this.disableConfirmButton = false;
    },
    validateUrl(url) {
      if (!url || url === '') {
        return true;
      }
      // eslint-disable-next-line
      return /[(http(s)?):\/\/a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(url);
    },
    validatePassword(password) {
      const regex = /^(?=.*\d)(?=.*[a-z])\S{4,}$/;
      return regex.test(password);
    },
    validateEmail(email) {
      // Función para validar el formato de correo electrónico
      const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      return regex.test(email);
    }
  }
});

</script>

<style scoped lang="scss">
.form-max-width {
  max-width: 700px;
}

.sign__input-wrapper span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.sign__input input {
  padding: 0 1rem;
}

.red {
  color: red;
}

.instructor-banner {
  background-image: url('@/assets/img/teacher/register/banner.jpg');
}
</style>