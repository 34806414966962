<template>
  <div v-for="(course) in courses" :key="course.courseId" class="col-xxl-12">
    <div class="course__item white-bg mb-30 fix">
      <div class="row gx-0">
        <div class="col-xxl-4 col-xl-4 col-lg-4">
          <div class="course__thumb course__thumb-list w-img p-relative fix">
            <div v-if="course.coverLinks" class=" w-100 min-height-img">
              <img :src="course.coverLinks.fileLink" alt="" class="h-max-img">
            </div>
            <div class="course__tag">
              <router-link :to="`/course-details/${course.courseId}`">
                {{ course?.categories && course.categories.length > 0 ? course?.categories[0] : '' }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-xxl-8 col-xl-8 col-lg-8">
          <div class="course__right">
            <div class="pb-60 course__content course__content-3">
              <div class="course__meta d-flex align-items-center" v-if="course.totalModules">
                <div class="course__lesson mr-20">
                  <span><i class="far fa-book-alt"></i>{{ course.totalModules}}  {{ $t('course.modules') }}</span>
                </div>
<!--                <div class="course__rating" v-if="course.rating > 0">
                  <span><i class="icon_star"></i>{{ course.rating }}</span>
                </div>-->
              </div>
              <h3 class="course__title course__title-3">
                <router-link :to="`/course-details/${course.courseId}`">{{ course.detail?.title }}</router-link>
              </h3>
              <div class="course__summary">
                <span v-if="!readMoreActivated[index]">{{ course.detail?.description.slice(0, 200) }}</span>
                <span class="link-primary c-pointer" v-if="!readMoreActivated[index] && course.detail?.description.length > 200" @click.self="()=> activateReadMore(index)">
                  Read more...
                </span>
                <span v-if="readMoreActivated[index]">{{ course.detail?.description }}</span>
                <span class="link-primary c-pointer" v-if="readMoreActivated[index]" @click.self="()=> activateReadMore(index)">
                  Read less...
                </span>
              </div>
              <div class="course__teacher d-flex align-items-center">
                <div class="course__teacher-thumb mr-15">
                  <img :src="course.userProfilePicture ?? require('@/assets/img/course/teacher/teacher.jpg')" alt="">
                </div>
                <h6>
                  <router-link :to="`/instructor-details?uid=${course.teacherId}`">
                    {{ course.teacherName }}
                  </router-link>
                </h6>
              </div>
            </div>
            <div class="course__more course__more-2 d-flex justify-content-between align-items-center">
              <div class="course__status d-flex align-items-center">
                <!--                <span :class="course.color">${{ course.price }}</span>-->
                <span>{{ course.isFree ? 'Free' : `$${course.priceUSD}` }}</span>
                <!--                <span class="old-price">${{ course.oldPrice }}</span>-->
              </div>
              <div class="course__btn">
                <router-link :to="`/course-details/${course.courseId}`" class="link-btn">
                  {{ $t('actions.knowMore') }}
                  <i class="far fa-arrow-right"></i>
                  <i class="far fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Courses} from '@/core/domain/entities/course.entity';

export default defineComponent({
  name: 'ListTabItems',
  props: {
    courses: {
      type: Object as PropType<Courses>
    }
  },
  data() {
    return {
      readMoreActivated: Array(this.$props.courses?.length).fill(false)
    }
  },
  methods: {
    activateReadMore(index: number) {
      this.readMoreActivated[index] = !this.readMoreActivated[index];
    },
  }
});
</script>

<style scoped lang="scss">
.course__content.pb-60 {
  padding-bottom: 60px !important;
}
.c-pointer {
  cursor: pointer;
}
</style>