import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-100" }
const _hoisted_2 = { class: "container-fluid d-flex" }
const _hoisted_3 = { class: "row flex-fill signuparea-row" }
const _hoisted_4 = { class: "col-md-7 col-sm-12 linear-bg-black d-flex flex-column justify-content-start align-items-center p-5" }
const _hoisted_5 = { class: "mt-30 text-white" }
const _hoisted_6 = { class: "list-inline d-flex flex-column align-items-start" }
const _hoisted_7 = { class: "list-inline-item mt-60 mb-60" }
const _hoisted_8 = { class: "list-inline-item mb-60" }
const _hoisted_9 = { class: "list-inline-item mb-60" }
const _hoisted_10 = { class: "col-md-5 col-sm-12 blue-bg-2 d-flex justify-content-center align-items-center p-relative" }
const _hoisted_11 = { class: "sign-home__wrapper" }
const _hoisted_12 = { class: "sign-home__form" }
const _hoisted_13 = { class: "sign-home__input-wrapper" }
const _hoisted_14 = { class: "sign-home__input" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "sign-home__input-wrapper" }
const _hoisted_17 = { class: "sign-home__input" }
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { class: "sign-home__input-wrapper" }
const _hoisted_20 = { class: "sign-home__input" }
const _hoisted_21 = ["type", "placeholder"]
const _hoisted_22 = { class: "sign-home__action d-flex justify-content-between mb-30 mt-3" }
const _hoisted_23 = { class: "sign-home__agree d-flex align-items-center" }
const _hoisted_24 = {
  class: "m-check-label",
  for: "m-agree"
}
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { class: "sign__new text-center mt-20" }
const _hoisted_27 = { href: "https://consumer.bullfy.com/" }
const _hoisted_28 = { class: "modal-dialog" }
const _hoisted_29 = { class: "modal-content" }
const _hoisted_30 = { class: "modal-header" }
const _hoisted_31 = { class: "modal-body" }
const _hoisted_32 = { class: "contact__form" }
const _hoisted_33 = { class: "row" }
const _hoisted_34 = { class: "col-xxl-12" }
const _hoisted_35 = { class: "contact__form-input" }
const _hoisted_36 = { class: "col-xxl-12" }
const _hoisted_37 = { class: "contact__btn" }
const _hoisted_38 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('signUp.boostYour')), 1),
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _cache[9] || (_cache[9] = _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 100.000000 100.000000",
                  preserveAspectRatio: "xMidYMid meet"
                }, [
                  _createElementVNode("g", {
                    transform: "translate(0.000000,100.000000) scale(0.100000,-0.100000)",
                    fill: "currentColor",
                    stroke: "none"
                  }, [
                    _createElementVNode("path", { d: "M127 969 c-48 -40 -67 -77 -67 -134 0 -49 10 -77 42 -111 19 -21 19\n-21 -4 -36 -13 -8 -31 -17 -40 -21 -10 -3 -18 -16 -18 -27 0 -30 56 -60 114\n-60 42 0 47 -2 41 -17 -4 -10 -12 -34 -18 -53 -10 -35 -8 -37 45 -90 62 -63\n95 -125 107 -201 6 -35 16 -59 30 -70 13 -11 21 -29 21 -49 0 -23 10 -42 34\n-66 30 -30 40 -34 86 -34 46 0 56 4 86 34 24 24 34 43 34 66 0 20 8 38 21 49\n14 11 24 35 30 70 12 76 45 138 107 201 53 53 55 55 45 90 -6 19 -14 43 -18\n53 -6 15 -1 17 41 17 58 0 114 30 114 59 0 13 -13 26 -35 37 -44 20 -53 34\n-31 46 46 26 62 137 27 194 -26 43 -82 84 -114 84 -33 0 -35 -21 -7 -57 11\n-14 20 -40 20 -58 0 -79 -98 -94 -213 -32 -45 24 -82 37 -107 37 -25 0 -62\n-13 -107 -37 -115 -62 -213 -47 -213 32 0 18 9 44 20 58 48 61 -7 81 -73 26z\nm432 -149 c22 -10 55 -39 76 -62 38 -45 135 -228 135 -256 0 -9 -20 -37 -44\n-62 -55 -56 -95 -134 -103 -200 -3 -27 -10 -50 -14 -50 -5 0 -9 7 -9 15 0 10\n-10 15 -31 15 -30 0 -31 -2 -27 -35 l4 -35 -46 0 -46 0 4 35 c4 33 3 35 -27\n35 -21 0 -31 -5 -31 -15 0 -8 -4 -15 -9 -15 -4 0 -11 23 -14 50 -8 66 -48 144\n-103 200 -24 25 -44 53 -44 62 0 28 97 211 135 256 40 46 96 81 133 82 12 0\n40 -9 61 -20z" })
                  ])
                ], -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.sellMore')), 1)
              ]),
              _createElementVNode("li", _hoisted_8, [
                _cache[10] || (_cache[10] = _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 100.000000 100.000000",
                  preserveAspectRatio: "xMidYMid meet"
                }, [
                  _createElementVNode("g", {
                    transform: "translate(0.000000,100.000000) scale(0.100000,-0.100000)",
                    fill: "currentColor",
                    stroke: "none"
                  }, [
                    _createElementVNode("path", { d: "M127 969 c-48 -40 -67 -77 -67 -134 0 -49 10 -77 42 -111 19 -21 19\n-21 -4 -36 -13 -8 -31 -17 -40 -21 -10 -3 -18 -16 -18 -27 0 -30 56 -60 114\n-60 42 0 47 -2 41 -17 -4 -10 -12 -34 -18 -53 -10 -35 -8 -37 45 -90 62 -63\n95 -125 107 -201 6 -35 16 -59 30 -70 13 -11 21 -29 21 -49 0 -23 10 -42 34\n-66 30 -30 40 -34 86 -34 46 0 56 4 86 34 24 24 34 43 34 66 0 20 8 38 21 49\n14 11 24 35 30 70 12 76 45 138 107 201 53 53 55 55 45 90 -6 19 -14 43 -18\n53 -6 15 -1 17 41 17 58 0 114 30 114 59 0 13 -13 26 -35 37 -44 20 -53 34\n-31 46 46 26 62 137 27 194 -26 43 -82 84 -114 84 -33 0 -35 -21 -7 -57 11\n-14 20 -40 20 -58 0 -79 -98 -94 -213 -32 -45 24 -82 37 -107 37 -25 0 -62\n-13 -107 -37 -115 -62 -213 -47 -213 32 0 18 9 44 20 58 48 61 -7 81 -73 26z\nm432 -149 c22 -10 55 -39 76 -62 38 -45 135 -228 135 -256 0 -9 -20 -37 -44\n-62 -55 -56 -95 -134 -103 -200 -3 -27 -10 -50 -14 -50 -5 0 -9 7 -9 15 0 10\n-10 15 -31 15 -30 0 -31 -2 -27 -35 l4 -35 -46 0 -46 0 4 35 c4 33 3 35 -27\n35 -21 0 -31 -5 -31 -15 0 -8 -4 -15 -9 -15 -4 0 -11 23 -14 50 -8 66 -48 144\n-103 200 -24 25 -44 53 -44 62 0 28 97 211 135 256 40 46 96 81 133 82 12 0\n40 -9 61 -20z" })
                  ])
                ], -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.registerAndWin')), 1)
              ]),
              _createElementVNode("li", _hoisted_9, [
                _cache[11] || (_cache[11] = _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 100.000000 100.000000",
                  preserveAspectRatio: "xMidYMid meet"
                }, [
                  _createElementVNode("g", {
                    transform: "translate(0.000000,100.000000) scale(0.100000,-0.100000)",
                    fill: "currentColor",
                    stroke: "none"
                  }, [
                    _createElementVNode("path", { d: "M127 969 c-48 -40 -67 -77 -67 -134 0 -49 10 -77 42 -111 19 -21 19\n-21 -4 -36 -13 -8 -31 -17 -40 -21 -10 -3 -18 -16 -18 -27 0 -30 56 -60 114\n-60 42 0 47 -2 41 -17 -4 -10 -12 -34 -18 -53 -10 -35 -8 -37 45 -90 62 -63\n95 -125 107 -201 6 -35 16 -59 30 -70 13 -11 21 -29 21 -49 0 -23 10 -42 34\n-66 30 -30 40 -34 86 -34 46 0 56 4 86 34 24 24 34 43 34 66 0 20 8 38 21 49\n14 11 24 35 30 70 12 76 45 138 107 201 53 53 55 55 45 90 -6 19 -14 43 -18\n53 -6 15 -1 17 41 17 58 0 114 30 114 59 0 13 -13 26 -35 37 -44 20 -53 34\n-31 46 46 26 62 137 27 194 -26 43 -82 84 -114 84 -33 0 -35 -21 -7 -57 11\n-14 20 -40 20 -58 0 -79 -98 -94 -213 -32 -45 24 -82 37 -107 37 -25 0 -62\n-13 -107 -37 -115 -62 -213 -47 -213 32 0 18 9 44 20 58 48 61 -7 81 -73 26z\nm432 -149 c22 -10 55 -39 76 -62 38 -45 135 -228 135 -256 0 -9 -20 -37 -44\n-62 -55 -56 -95 -134 -103 -200 -3 -27 -10 -50 -14 -50 -5 0 -9 7 -9 15 0 10\n-10 15 -31 15 -30 0 -31 -2 -27 -35 l4 -35 -46 0 -46 0 4 35 c4 33 3 35 -27\n35 -21 0 -31 -5 -31 -15 0 -8 -4 -15 -9 -15 -4 0 -11 23 -14 50 -8 66 -48 144\n-103 200 -24 25 -44 53 -44 62 0 28 97 211 135 256 40 46 96 81 133 82 12 0\n40 -9 61 -20z" })
                  ])
                ], -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.opportunity')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t('signUp.name')), 1),
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fullName) = $event)),
                      placeholder: _ctx.$t('signUp.yourName'),
                      required: ""
                    }, null, 8, _hoisted_15), [
                      [_vModelText, _ctx.fullName]
                    ]),
                    _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fal fa-user" }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t('signUp.email')), 1),
                  _createElementVNode("div", _hoisted_17, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                      placeholder: _ctx.$t('signUp.yourEmail'),
                      required: ""
                    }, null, 8, _hoisted_18), [
                      [_vModelText, _ctx.email]
                    ]),
                    _cache[13] || (_cache[13] = _createElementVNode("i", { class: "fal fa-envelope" }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t('signUp.password')), 1),
                  _createElementVNode("div", _hoisted_20, [
                    _withDirectives(_createElementVNode("input", {
                      type: _ctx.showPassword? 'text': 'password',
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                      placeholder: _ctx.$t('signUp.yourPassword'),
                      required: ""
                    }, null, 8, _hoisted_21), [
                      [_vModelDynamic, _ctx.password]
                    ]),
                    _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fal fa-lock" }, null, -1)),
                    _createElementVNode("a", {
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.togglePasswordVisibility && _ctx.togglePasswordVisibility(...args)))
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass([_ctx.showPassword ? 'fal fa-eye-slash' : 'fal fa-eye', "sign-home__input-wrapper-icon-right"])
                      }, null, 2)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _withDirectives(_createElementVNode("input", {
                      class: "m-check-input",
                      type: "checkbox",
                      id: "m-agree",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.termsAccepted) = $event)),
                      require: ""
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.termsAccepted]
                    ]),
                    _createElementVNode("label", _hoisted_24, [
                      _createTextVNode(_toDisplayString(_ctx.$t('signUp.accept')) + " ", 1),
                      _createVNode(_component_router_link, { to: "/terms" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('signUp.terms')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _createElementVNode("button", {
                  class: "e-btn w-100",
                  type: "button",
                  disabled: _ctx.disableButton,
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.register && _ctx.register(...args)))
                }, _toDisplayString(_ctx.$t('signUp.register')), 9, _hoisted_25),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('signUp.alreadyRegistered')) + " ", 1),
                    _createElementVNode("a", _hoisted_27, _toDisplayString(_ctx.$t('signUp.signIn')), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_b_modal, {
        id: "modal-signup-otp",
        centered: "",
        modelValue: _ctx.isModalOTPVisible,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isModalOTPVisible) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('signUp.otpTitle')), 1),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("form", null, [
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("span", null, [
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.otp')) + " ", 1),
                            _cache[15] || (_cache[15] = _createElementVNode("i", { class: "red" }, "*", -1))
                          ]),
                          _createElementVNode("div", _hoisted_35, [
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              name: "title",
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.otp) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.otp]
                            ])
                          ])
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('signUp.otpText')), 1),
                        _createElementVNode("div", _hoisted_36, [
                          _createElementVNode("div", _hoisted_37, [
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.confirmOTP && _ctx.confirmOTP(...args))),
                              class: "e-btn",
                              disabled: _ctx.disableConfirmButton
                            }, _toDisplayString(_ctx.$t('signUp.confirmOTP')), 9, _hoisted_38)
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}