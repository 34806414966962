import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "course__area pt-15 pb-120" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "course__tab-inner grey-bg-2 mb-50" }
const _hoisted_4 = { class: "row align-items-center" }
const _hoisted_5 = { class: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6" }
const _hoisted_6 = { class: "course__tab-wrapper d-flex align-items-center" }
const _hoisted_7 = { class: "course__tab-btn" }
const _hoisted_8 = {
  class: "nav nav-tabs",
  id: "courseTab",
  role: "tablist"
}
const _hoisted_9 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_10 = ["aria-selected"]
const _hoisted_11 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_12 = ["aria-selected"]
const _hoisted_13 = { class: "course__view" }
const _hoisted_14 = { class: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6" }
const _hoisted_15 = { class: "course__sort d-flex justify-content-sm-end" }
const _hoisted_16 = { class: "course__sort-inner" }
const _hoisted_17 = {
  selected: true,
  value: {}
}
const _hoisted_18 = ["value"]
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-xxl-12" }
const _hoisted_21 = { class: "course__tab-conent" }
const _hoisted_22 = {
  class: "tab-content",
  id: "courseTabContent"
}
const _hoisted_23 = {
  class: "tab-pane fade show active",
  id: "grid",
  role: "tabpanel",
  "aria-labelledby": "grid-tab"
}
const _hoisted_24 = { class: "row" }
const _hoisted_25 = {
  class: "tab-pane fade",
  id: "list",
  role: "tabpanel",
  "aria-labelledby": "list-tab"
}
const _hoisted_26 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GridTabItems = _resolveComponent("GridTabItems")!
  const _component_ListTabItems = _resolveComponent("ListTabItems")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("ul", _hoisted_8, [
                  _createElementVNode("li", _hoisted_9, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["nav-link", {active: _ctx.showCoursesAs === 'grid'}]),
                      onClick: _cache[0] || (_cache[0] = () => _ctx.changeDataShownAs('grid')),
                      id: "grid-tab",
                      "data-bs-toggle": "tab",
                      "data-bs-target": "#grid",
                      type: "button",
                      role: "tab",
                      "aria-controls": "grid",
                      "aria-selected": _ctx.showCoursesAs === 'grid'
                    }, _cache[5] || (_cache[5] = [
                      _createStaticVNode("<svg class=\"grid\" viewBox=\"0 0 24 24\"><rect x=\"3\" y=\"3\" class=\"st0\" width=\"7\" height=\"7\"></rect><rect x=\"14\" y=\"3\" class=\"st0\" width=\"7\" height=\"7\"></rect><rect x=\"14\" y=\"14\" class=\"st0\" width=\"7\" height=\"7\"></rect><rect x=\"3\" y=\"14\" class=\"st0\" width=\"7\" height=\"7\"></rect></svg>", 1)
                    ]), 10, _hoisted_10)
                  ]),
                  _createElementVNode("li", _hoisted_11, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["nav-link list", {active: _ctx.showCoursesAs === 'list'}]),
                      onClick: _cache[1] || (_cache[1] = () => _ctx.changeDataShownAs('list')),
                      id: "list-tab",
                      "data-bs-toggle": "tab",
                      "data-bs-target": "#list",
                      type: "button",
                      role: "tab",
                      "aria-controls": "list",
                      "aria-selected": _ctx.showCoursesAs === 'list'
                    }, _cache[6] || (_cache[6] = [
                      _createStaticVNode("<svg class=\"list\" viewBox=\"0 0 512 512\"><g id=\"Layer_2_1_\"><path class=\"st0\" d=\"M448,69H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,69,448,69z\"></path><circle class=\"st0\" cx=\"64\" cy=\"100\" r=\"31\"></circle><path class=\"st0\" d=\"M448,225H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,225,448,225z\"></path><circle class=\"st0\" cx=\"64\" cy=\"256\" r=\"31\"></circle><path class=\"st0\" d=\"M448,381H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,381,448,381z\"></path><circle class=\"st0\" cx=\"64\" cy=\"412\" r=\"31\"></circle></g></svg>", 1)
                    ]), 10, _hoisted_12)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('pagination.showing')) + " " + _toDisplayString(`${_ctx.courses.metadata.page === 1 ? 1 : ((20 * _ctx.courses.metadata.page) + 1)} - ${_ctx.courses.metadata.total < 20 ? _ctx.courses.metadata.total : _ctx.courses.metadata.page === _ctx.courses.metadata.totalPages ? _ctx.courses.metadata.total % 20 : (20 * _ctx.courses.metadata.page)}  ${_ctx.$t('pagination.of')} ${_ctx.courses.metadata.total}`), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getCourses(1, _ctx.$i18n.locale, _ctx.selectedCategory.name)))
                }, [
                  _createElementVNode("option", _hoisted_17, _toDisplayString(_ctx.$t('course.all')), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: category,
                      key: category.categoryId
                    }, _toDisplayString(category.name), 9, _hoisted_18))
                  }), 128))
                ], 544), [
                  [_vModelSelect, _ctx.selectedCategory]
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_component_GridTabItems, {
                    courses: _ctx.courses.data
                  }, null, 8, ["courses"])
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_ListTabItems, {
                    courses: _ctx.courses.data
                  }, null, 8, ["courses"])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_Pagination, {
        "current-page": _ctx.courses.metadata.page,
        "total-pages": _ctx.courses.metadata.totalPages,
        "onUpdate:page": _cache[4] || (_cache[4] = page => _ctx.getCourses(page, _ctx.$i18n.locale))
      }, null, 8, ["current-page", "total-pages"])
    ])
  ]))
}