<template>
  <div v-if="show" class="cookie-consent z-50">
    <p>{{ $t('cookieConsent.message') }}</p>
    <button @click="acceptCookies">{{ $t('cookieConsent.accept') }}</button>
  </div>
</template>

<script>
export default {
  name: 'CookiesConsent',

  data() {
    return {
      show: !localStorage.getItem('cookiesAccepted')
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', true);
      this.show = false;
    }
  }
};
</script>


<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9); ;
  color: #fff;
  text-align: center;
  padding: 1rem;
}
.cookie-consent button {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
}
</style>