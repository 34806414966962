<template>
  <Header/>
  <SignUpArea/>
  <HeroSection/>
  <ServicesArea/>
  <TeachersArea/>
<!--  <CategoryArea/>-->
  <ModuleFive/>
  <Course/>
  <Events/>
  <Module_seven/>
  <CookieConsent/>
  <Footer footerPadding="true"/>
</template>

<script>
import Header from '../components/Home/Header.vue';
import HeroSection from '../components/Home/HeroSection.vue';
import CookieConsent from '../components/common/CookieConsent.vue';
//import CategoryArea from '../components/Home/CategoryArea.vue';
import ModuleFive from '../components/Home/Module_five.vue';
import Course from '../components/Home/Course.vue';
import Events from '../components/Home/Events.vue';
import Module_seven from '../components/Home/Module_seven.vue';
import Footer from '../components/Home/Footer.vue';
import SignUpArea from "@/presentation/components/Home/SignUpArea.vue";
import TeachersArea from "@/presentation/components/Home/Teachers.vue";
import ServicesArea from "../components/Home/Services.vue";

export default {
  name: 'HomePage',
  components: {
    ServicesArea,
    TeachersArea,
    SignUpArea,
    Header,
    HeroSection,
    ModuleFive,
    Course,
    Events,
    Module_seven,
    Footer,
    CookieConsent
  }
}
</script>