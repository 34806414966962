<template>
   <section class="faq__area mx-auto p-8 bg-gray-50" style="width: 60%; border-radius: 20%;">
      <div>
         <!-- Section Title -->
         <div class="mb-8">
            <h1 class="text-2xl font-bold text-gray-700 mb-4 ">{{ $t('about.informationMenu') }}</h1>
            <hr class="border-t border-gray-300 mt-2">
         </div>
         <!-- Questions and Answers -->
         <div class="space-y-6">
            <!-- Preguntas Generales -->
            <div>
               <div @click="toggleAnswer(1)"
                  class="flex justify-between items-center cursor-pointer text-lg font-bold text-gray-800">
                  <div class="content_con">
                     <h3 class="flex-1 text-left">{{ $t('about.generalInquiries') }}</h3>
                     <span class="text-right">{{ activeQuestion === 1 ? "-" : "+" }}</span>
                  </div>
                  <hr class="border-t border-gray-300 mt-2">
               </div>
               <div v-if="activeQuestion === 1" class="mt-4 space-y-4 text-gray-600">
                  <div>
                     <h3 class="font-semibold">{{ $t('about.whatIsBullfy') }}</h3>
                     <p>
                        {{ $t('about.bullfyIsANetwork') }}
                     </p>
                  </div>
                  <div>
                     <h3 class="font-semibold">{{ $t('about.howDoIJoin') }}</h3>
                     <p>
                        {{ $t('about.toJoinBullfy') }}
                        <a href="https://www.bullfy.com" target="_blank"
                           class="text-blue-600 underline">www.bullfy.com</a>,
                        {{ $t('about.whereYouCan') }}
                     </p>
                  </div>
                  <div>
                     <h3 class="font-semibold">{{ $t('about.whatCanIfind') }}</h3>
                     <p>
                        {{ $t('about.onBullfy') }}
                     </p>
                  </div>
                  <div>
                     <h3 class="font-semibold">{{ $t('about.whyShould') }}</h3>
                     <p>
                        {{ $t('about.unlikeOther') }}
                     </p>
                  </div>
               </div>
            </div>

            <!-- Preguntas sobre el beneficio -->
            <div>
               <div @click="toggleAnswer(2)"
                  class="flex justify-between items-center cursor-pointer text-lg font-bold text-gray-800">
                  <div class="content_con">
                     <h3>{{ $t('about.benefits.title') }}</h3>
                     <span>{{ activeQuestion === 2 ? "-" : "+" }}</span>
                  </div>
                  <hr class="border-t border-gray-300 mt-2">
               </div>
               <div v-if="activeQuestion === 2" class="mt-4 space-y-4 text-gray-600">
                  <div>
                     <h3 class="font-semibold">{{ $t('about.benefits.whatAreTheBenefits') }}</h3>
                     <p>
                        {{ $t('about.benefits.bullfyIsFree') }}
                     </p>
                  </div>
                  <div class="cookie-item">
                     <p class="cookie-description">
                        {{ $t('about.benefits.paymentSystemDesc') }} </p>
                  </div>
                  <div class="cookie-item">
                     <span class="cookie-label">● {{ $t('about.benefits.affiliateProgram') }} </span>
                     <p class="cookie-description">
                        {{ $t('about.benefits.affiliateProgramDesc') }}
                     </p>
                  </div>
                  <div class="cookie-item">
                     <span class="cookie-label">● {{ $t('about.benefits.emailMarketing') }} </span>
                     <p class="cookie-description">
                        {{ $t('about.benefits.emailMarketingDesc') }}
                     </p>
                  </div>
                  <div class="cookie-item">
                     <span class="cookie-label">● {{ $t('about.benefits.webinars') }} </span>

                     <div>
                        <p> {{ $t('about.benefits.webinarsDesc') }}</p>
                        <p> • {{ $t('about.benefits.attract') }}</p>
                        <p> • {{ $t('about.benefits.offer') }}</p>
                     </div>
                  </div>
               </div>
            </div>

            <!-- Políticas de Cookies -->
            <div>
               <div @click="toggleAnswer(3)"
                  class="flex justify-between items-center cursor-pointer text-lg font-bold text-gray-800">
                  <div class="content_con">
                     <h3>{{ $t('about.cookiePolicy.cookiePolicy') }}</h3>
                     <span>{{ activeQuestion === 3 ? "-" : "+" }}</span>
                  </div>
                  <hr class="border-t border-gray-300 mt-2">
               </div>
               <div v-if="activeQuestion === 3" class="mt-4 space-y-4 text-gray-600">
                  <div>
                     <p>
                        {{ $t('about.cookiePolicy.description') }}
                     </p>
                  </div>
                  <div>
                     <h3 class="font-semibold">{{ $t('about.cookiePolicy.whatAreCookies') }}</h3>
                     <p>
                        {{ $t('about.cookiePolicy.whatAreCookiesDesc') }}
                     </p>
                  </div>
                  <div>
                     <h3 class="font-semibold">{{ $t('about.cookiePolicy.typesOfCookies') }}</h3>
                     <div class="cookie-item">
                        <span class="cookie-label">● {{ $t('about.cookiePolicy.essentialCookies') }}</span>
                        <p class="cookie-description">
                           {{ $t('about.cookiePolicy.necessaryDesc') }}
                        </p>
                     </div>
                     <div class="cookie-item">
                        <span class="cookie-label">● {{ $t('about.cookiePolicy.performanceCookies') }}</span>
                        <p class="cookie-description">
                           {{ $t('about.cookiePolicy.performanceCookiesDesc') }}
                        </p>
                     </div>
                     <div class="cookie-item">
                        <span class="cookie-label">● {{ $t('about.cookiePolicy.functionalCookies') }} </span>
                        <p class="cookie-description">
                           {{ $t('about.cookiePolicy.functionalCookiesDesc') }}
                        </p>
                     </div>
                     <div class="cookie-item">
                        <span class="cookie-label">● {{ $t('about.cookiePolicy.advertisingCookies') }}</span>
                        <p class="cookie-description">
                           {{ $t('about.cookiePolicy.advertisingCookiesDesc') }}
                        </p>
                     </div>
                  </div>
                  <div>
                     <h3 class="font-semibold">{{ $t('about.cookiePolicy.howWeUse') }}</h3>
                     <div class="cookie-item">
                        <p class="cookie-description">
                           ● {{ $t('about.cookiePolicy.toAuthenticate') }}
                        </p>
                     </div>
                     <div class="cookie-item">
                        <p class="cookie-description">
                           ● {{ $t('about.cookiePolicy.toSaveYour') }}
                        </p>
                     </div>
                     <div class="cookie-item">
                        <p class="cookie-description">
                           ● {{ $t('about.cookiePolicy.toTack') }}
                        </p>
                     </div>
                  </div>
                  <div>
                     <h3 class="font-semibold">{{ $t('about.cookiePolicy.cookieManage') }}</h3>
                     <div class="cookie-item">
                        <p class="cookie-description">
                           {{ $t('about.cookiePolicy.youCanManage') }}
                        </p>
                     </div>
                  </div>
               </div>
            </div>

            <div>
               <div @click="toggleAnswer(4)"
                  class="flex justify-between items-center cursor-pointer text-lg font-bold text-gray-800">
                  <div class="content_con">
                     <h3>{{ $t('about.legalNotice.legalNotice') }}</h3>
                     <span>{{ activeQuestion === 4 ? "-" : "+" }}</span>
                  </div>
                  <hr class="border-t border-gray-300 mt-2">
               </div>
               <div v-if="activeQuestion === 4" class="mt-4 space-y-4 text-gray-600">
                  <div>
                     <p>
                        {{ $t('about.legalNotice.legalNoticeDesc') }}
                     </p>
                  </div>
                  <div class="cookie-item">
                     <span class="cookie-label">● {{ $t('about.legalNotice.companyName') }}</span>
                     <p class="cookie-description">
                        Chaturi LLC-FZ
                     </p>
                  </div>
                  <div>
                     <div class="cookie-item">
                        <span class="cookie-label">● {{ $t('about.legalNotice.companyAddress') }}</span>
                        <p class="cookie-description">
                           Meydan Grandstand, 6th floor, Meydan Road, Nad Al Sheba, Dubái,
                           Dubái
                        </p>
                     </div>
                     <div class="cookie-item">
                        <span class="cookie-label">● {{ $t('about.legalNotice.contactPhoneNUmber') }}</span>
                        <p class="cookie-description">
                           +971555276812
                        </p>
                     </div>
                     <div class="cookie-item">
                        <span class="cookie-label">● {{ $t('about.legalNotice.contactE') }}</span>
                        <p class="cookie-description">
                           support@bullfy.com
                        </p>
                     </div>

                  </div>

                  <div>
                     <p>{{ $t('about.legalNotice.informationCourses') }}</p>
                  </div>

                  <div>
                     <h3>{{ $t('about.legalNotice.intellectualProperty') }}</h3>
                    <p>{{ $t('about.legalNotice.copyright1') }}</p>
                    <p>Está prohibido el uso, reproducción o distribución no autorizados de nuestros materiales.Para
                      obtener permisos o realizar consultas, contáctanos en support@bullfy.com.</p>
                    <p>Para obtener permisos o realizar consultas, contáctanos en support@bullfy.com.</p>
                  </div>

               </div>
            </div>

            <div>
               <div @click="toggleAnswer(5)"
                  class="flex justify-between items-center cursor-pointer text-lg font-bold text-gray-800">
                  <div class="content_con">
                     <h3>{{ $t('about.termsAndConditions.termsAndConditions') }}</h3>
                     <span>{{ activeQuestion === 5 ? "-" : "+" }}</span>
                  </div>
                  <hr class="border-t border-gray-300 mt-2">
               </div>
               <div v-if="activeQuestion === 5" class="mt-4 space-y-4 text-gray-600">
                  <div>
                     <p>
                        {{ $t('about.termsAndConditions.welcomeToBullfy') }}
                     </p>
                  </div>
                  <ul class="list-disc pl-5 space-y-3">
                     <li>
                        <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.accountRegistration') }}</strong>
                        <ul class="list-disc pl-5 space-y-1">
                           <li>{{ $t('about.termsAndConditions.toAccessAndPurchase') }}</li>
                           <li>{{ $t('about.termsAndConditions.youAreResponsible') }}
                           </li>
                        </ul>
                     </li>
                     <li>
                        <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.useOfServices') }}</strong>
                        <ul class="list-disc pl-5 space-y-1">
                           <li>{{ $t('about.termsAndConditions.ourCourses') }}</li>
                           <li>{{ $t('about.termsAndConditions.redistribution') }}</li>
                        </ul>
                     </li>
                     <li>
                        <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.payments') }}</strong>
                        <ul class="list-disc pl-5 space-y-1">
                           <li>{{ $t('about.termsAndConditions.coursePayments') }}</li>
                           <li>
                              {{ $t('about.termsAndConditions.refundsAreIssued') }}
                           </li>
                        </ul>
                     </li>
                     <li>
                        <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.intellectual') }}</strong>
                        <ul class="list-disc pl-5 space-y-1">
                           <li>{{ $t('about.termsAndConditions.allCourses') }}</li>
                           <li>{{ $t('about.termsAndConditions.youMayNot') }}</li>
                        </ul>
                     </li>
                     <li>
                        <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.limitation') }}</strong>
                        <ul class="list-disc pl-5 space-y-1">
                           <li>{{ $t('about.termsAndConditions.problemsarising') }}</li>
                           <li>{{ $t('about.termsAndConditions.theResultsObtained') }}</li>
                        </ul>
                     </li>
                     <li>
                        <strong class="cookie-label"> ● {{ $t('about.termsAndConditions.changes') }}</strong>
                        <p>
                           {{ $t('about.termsAndConditions.changesDesc') }}
                        </p>
                     </li>
                  </ul>
               </div>
            </div>

            <div>
               <div @click="toggleAnswer(6)"
                  class="flex justify-between items-center cursor-pointer text-lg font-bold text-gray-800">
                  <div class="content_con">
                     <h3>{{ $t('about.privacyPolicy.privacyPolicy') }}</h3>
                     <span>{{ activeQuestion === 6 ? "-" : "+" }}</span>
                  </div>
                  <hr class="border-t border-gray-300 mt-2">
               </div>
               <div v-if="activeQuestion === 6" class="mt-4 space-y-4 text-gray-600">
                  <div>
                     <p>
                        {{ $t('about.privacyPolicy.bullfyValues') }}
                     </p>
                  </div>
                  <ul class="list-disc pl-5 space-y-3">
                     <li>
                        <span class="text-black font-bold">● {{ $t('about.privacyPolicy.informationWe') }}</span>
                        <ul class="list-disc pl-5 space-y-1">
                           <li>
                              <span class="text-black font-bold">● {{ $t('about.privacyPolicy.personalInfo') }}</span> {{ $t('about.privacyPolicy.billingInformation') }}
                           </li>
                           <li>
                              <span class="text-black font-bold">● {{ $t('about.privacyPolicy.usageData') }}</span> {{ $t('about.privacyPolicy.deviceInformation') }}
                           </li>
                           <li>
                              <span class="text-black font-bold">● Cookies:</span> {{ $t('about.privacyPolicy.seeOur') }}
                           </li>
                        </ul>
                     </li>
                  </ul>

                  <ul class="list-disc pl-5 space-y-3">
                     <li>
                        <span class="text-black font-bold">● {{ $t('about.privacyPolicy.howWeUse') }}</span>
                        <ul class="list-disc pl-5 space-y-1">
                           <li>
                              <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.toProvide') }}
                           </li>
                           <li>
                              <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.toProcess') }}
                           </li>
                           <li>
                              <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.toCommunicate') }}
                           </li>
                           <li>
                              <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.toComply') }}
                           </li>
                        </ul>
                     </li>
                  </ul>

                  <ul class="list-disc pl-5 space-y-3">
                     <li>
                        <span class="text-black font-bold">● {{ $t('about.privacyPolicy.sharingYour') }}</span>
                        <ul class="list-disc pl-5 space-y-1">
                           <p> {{ $t('about.privacyPolicy.weDoNot') }}</p>

                           <li>
                              <span class="text-black font-bold">● </span> {{ $t('about.privacyPolicy.payment') }}                            
                           </li>
                           <li>
                              <span class="text-black font-bold">●</span>  {{ $t('about.privacyPolicy.authorities') }}  
                           </li>
                        </ul>
                     </li>
                  </ul>

                  <ul class="list-disc pl-5 space-y-3">
                     <li>
                        <span class="text-black font-bold">● {{ $t('about.privacyPolicy.yourRights') }} </span>
                        <ul class="list-disc pl-5 space-y-1">
                           <p>{{ $t('about.privacyPolicy.dependingOnYour') }} </p>

                           <li>
                              <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.accessAndUpdaste') }}
                           </li>
                           <li>
                              <span class="text-black font-bold">●</span>{{ $t('about.privacyPolicy.requestDeletion') }}
                           </li>
                           <li>
                              <span class="text-black font-bold">●</span> {{ $t('about.privacyPolicy.withdraw') }}
                           </li>
                        </ul>
                     </li>
                  </ul>

                  <ul class="list-disc pl-5 space-y-3">
                     <li>
                        <span class="text-black font-bold">● {{ $t('about.privacyPolicy.dataSecurity') }}</span>
                        <ul class="list-disc pl-5 space-y-1">
                           <p>{{ $t('about.privacyPolicy.weEmploy') }}</p>
                        </ul>
                     </li>
                  </ul>

                  <ul class="list-disc pl-5 space-y-3">
                     <li>
                        <span class="text-black font-bold">● {{ $t('about.privacyPolicy.updates') }}</span>
                        <div class="list-disc pl-5">
                           <p>{{ $t('about.privacyPolicy.updatesDesc1') }}</p>
                           <p>{{ $t('about.privacyPolicy.updatesDesc2') }}</p>
                           <p>{{ $t('about.privacyPolicy.updatesDesc3') }}</p>
                        </div>
                     </li>
                  </ul>

               </div>
            </div>
         </div>
      </div>
   </section>
</template>


<script>
export default {
   name: "FAQArea",
   data() {
      return {
         activeQuestion: null, // Rastrea la pregunta activa
      };
   },
   methods: {
      // Método para cambiar el estado de la pregunta activa
      toggleAnswer(questionId) {
         // Si la pregunta clickeada es la misma que la activa, se cierra; de lo contrario, se abre
         this.activeQuestion = this.activeQuestion === questionId ? null : questionId;
      },
   },
};
</script>


<style scoped>
.content_con {
   display: flex;
   justify-content: space-between;
}

.cookie-item {
   display: grid;
   align-items: baseline;
   gap: 0.5rem;
   margin-bottom: 0.5rem;
}

.cookie-label {
   font-weight: bold;
   color: black;
   display: inline;
}

.cookie-description {
   margin: 0;
   display: inline;
}

.list-disc {
   list-style-type: disc;
}

.pl-5 {
   padding-left: 1.25rem;
}

.space-y-3 {
   margin-bottom: 0.75rem;
}

.space-y-1 {
   margin-bottom: 0.25rem;
}

.font-bold {
   font-weight: bold;
}

</style>