import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page__title--area page__title-height page__title-overlay page__title-wrapper d-flex align-items-center instructor-banner" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-12" }
const _hoisted_5 = { class: "page__title-wrapper" }
const _hoisted_6 = { class: "page__title" }
const _hoisted_7 = { class: "page-area" }
const _hoisted_8 = { class: "container form-max-width" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "sign__wrapper white-bg" }
const _hoisted_12 = { class: "d-flex flex-column gap-2" }
const _hoisted_13 = { class: "sign__input-wrapper" }
const _hoisted_14 = { class: "sign__input" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "sign__input-wrapper" }
const _hoisted_17 = { class: "sign__input" }
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { class: "sign__input-wrapper" }
const _hoisted_20 = { class: "sign__input" }
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = { class: "sign__input-wrapper" }
const _hoisted_23 = { class: "sign__input" }
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = { class: "sign__input-wrapper" }
const _hoisted_26 = { class: "sign__input" }
const _hoisted_27 = ["placeholder"]
const _hoisted_28 = { class: "mt-20" }
const _hoisted_29 = { class: "d-flex flex-column gap-2" }
const _hoisted_30 = { class: "sign__input-wrapper" }
const _hoisted_31 = { class: "sign__input" }
const _hoisted_32 = ["placeholder"]
const _hoisted_33 = { class: "sign__input-wrapper" }
const _hoisted_34 = { class: "sign__input" }
const _hoisted_35 = ["placeholder"]
const _hoisted_36 = { class: "sign__action d-flex justify-content-between mb-30" }
const _hoisted_37 = { class: "sign__agree d-flex align-items-center" }
const _hoisted_38 = {
  class: "m-check-label",
  for: "m-agree"
}
const _hoisted_39 = ["disabled"]
const _hoisted_40 = { class: "modal-dialog" }
const _hoisted_41 = { class: "modal-content" }
const _hoisted_42 = { class: "modal-header" }
const _hoisted_43 = { class: "modal-body" }
const _hoisted_44 = { class: "contact__form" }
const _hoisted_45 = { class: "row" }
const _hoisted_46 = { class: "col-xxl-12" }
const _hoisted_47 = { class: "contact__form-input" }
const _hoisted_48 = { class: "col-xxl-12" }
const _hoisted_49 = { class: "contact__btn" }
const _hoisted_50 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('signUp.instructor.title')), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("form", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", null, [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.firstName')) + " ", 1),
                    _cache[13] || (_cache[13] = _createElementVNode("i", { class: "red" }, "*", -1))
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.firstName) = $event)),
                      placeholder: _ctx.$t('signUp.yourName'),
                      type: "text",
                      required: ""
                    }, null, 8, _hoisted_15), [
                      [_vModelText, _ctx.form.firstName]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", null, [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.lastName')) + " ", 1),
                    _cache[14] || (_cache[14] = _createElementVNode("i", { class: "red" }, "*", -1))
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.lastName) = $event)),
                      placeholder: _ctx.$t('signUp.lastNamePlaceHolder'),
                      type: "text",
                      required: ""
                    }, null, 8, _hoisted_18), [
                      [_vModelText, _ctx.form.lastName]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("span", null, [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.email')) + " ", 1),
                    _cache[15] || (_cache[15] = _createElementVNode("i", { class: "red" }, "*", -1))
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
                      placeholder: _ctx.$t('signUp.yourEmail'),
                      type: "email",
                      onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.setUsername && _ctx.setUsername(...args))),
                      required: ""
                    }, null, 40, _hoisted_21), [
                      [_vModelText, _ctx.form.email]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("span", null, [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.password')) + " ", 1),
                    _cache[16] || (_cache[16] = _createElementVNode("i", { class: "red" }, "*", -1))
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.password) = $event)),
                      placeholder: _ctx.$t('signUp.yourPassword'),
                      type: "password",
                      required: ""
                    }, null, 8, _hoisted_24), [
                      [_vModelText, _ctx.form.password]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("span", null, [
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.repeatPassword')) + " ", 1),
                    _cache[17] || (_cache[17] = _createElementVNode("i", { class: "red" }, "*", -1))
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.repeatPassword) = $event)),
                      placeholder: _ctx.$t('signUp.yourPassword'),
                      type: "password",
                      required: ""
                    }, null, 8, _hoisted_27), [
                      [_vModelText, _ctx.form.repeatPassword]
                    ])
                  ])
                ])
              ]),
              _createElementVNode("h3", _hoisted_28, _toDisplayString(_ctx.$t('signUp.authorInfo')), 1),
              _createElementVNode("form", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _cache[18] || (_cache[18] = _createElementVNode("span", null, " Facebook", -1)),
                  _createElementVNode("div", _hoisted_31, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.facebook) = $event)),
                      placeholder: _ctx.$t('signUp.urlPlaceHolder'),
                      type: "url"
                    }, null, 8, _hoisted_32), [
                      [_vModelText, _ctx.form.facebook]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _cache[19] || (_cache[19] = _createElementVNode("span", null, " Instagram", -1)),
                  _createElementVNode("div", _hoisted_34, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.instagram) = $event)),
                      placeholder: _ctx.$t('signUp.urlPlaceHolder'),
                      type: "url"
                    }, null, 8, _hoisted_35), [
                      [_vModelText, _ctx.form.instagram]
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _withDirectives(_createElementVNode("input", {
                    class: "m-check-input",
                    type: "checkbox",
                    id: "m-agree",
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.termsAccepted) = $event)),
                    required: ""
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.termsAccepted]
                  ]),
                  _createElementVNode("label", _hoisted_38, [
                    _createTextVNode(_toDisplayString(_ctx.$t('signUp.accept')) + " ", 1),
                    _createVNode(_component_router_link, { to: "terms" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('signUp.terms')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _createElementVNode("button", {
                class: "e-btn w-100",
                type: "button",
                disabled: _ctx.disableButton,
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.register && _ctx.register(...args)))
              }, _toDisplayString(_ctx.$t('signUp.register')), 9, _hoisted_39)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_b_modal, {
        id: "modal-signup-otp",
        centered: "",
        modelValue: _ctx.isModalOTPVisible,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.isModalOTPVisible) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_40, [
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('signUp.otpTitle')), 1),
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("form", null, [
                      _createElementVNode("div", _hoisted_45, [
                        _createElementVNode("div", _hoisted_46, [
                          _createElementVNode("span", null, [
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('signUp.otp')) + " ", 1),
                            _cache[20] || (_cache[20] = _createElementVNode("i", { class: "red" }, "*", -1))
                          ]),
                          _createElementVNode("div", _hoisted_47, [
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              name: "title",
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.otp) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.otp]
                            ])
                          ])
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('signUp.otpText')), 1),
                        _createElementVNode("div", _hoisted_48, [
                          _createElementVNode("div", _hoisted_49, [
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.confirmOTP && _ctx.confirmOTP(...args))),
                              class: "e-btn",
                              disabled: _ctx.disableConfirmButton
                            }, _toDisplayString(_ctx.$t('signUp.confirmOTP')), 9, _hoisted_50)
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}