import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "course__item white-bg mb-30 fix" }
const _hoisted_2 = { class: "row gx-0" }
const _hoisted_3 = { class: "col-xxl-4 col-xl-4 col-lg-4" }
const _hoisted_4 = { class: "course__thumb course__thumb-list w-img p-relative fix" }
const _hoisted_5 = {
  key: 0,
  class: "w-100 min-height-img"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "course__tag" }
const _hoisted_8 = { class: "col-xxl-8 col-xl-8 col-lg-8" }
const _hoisted_9 = { class: "course__right" }
const _hoisted_10 = { class: "pb-60 course__content course__content-3" }
const _hoisted_11 = {
  key: 0,
  class: "course__meta d-flex align-items-center"
}
const _hoisted_12 = { class: "course__lesson mr-20" }
const _hoisted_13 = { class: "course__title course__title-3" }
const _hoisted_14 = { class: "course__summary" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { class: "course__teacher d-flex align-items-center" }
const _hoisted_18 = { class: "course__teacher-thumb mr-15" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "course__more course__more-2 d-flex justify-content-between align-items-center" }
const _hoisted_21 = { class: "course__status d-flex align-items-center" }
const _hoisted_22 = { class: "course__btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
    return (_openBlock(), _createElementBlock("div", {
      key: course.courseId,
      class: "col-xxl-12"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (course.coverLinks)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("img", {
                      src: course.coverLinks.fileLink,
                      alt: "",
                      class: "h-max-img"
                    }, null, 8, _hoisted_6)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: `/course-details/${course.courseId}`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(course?.categories && course.categories.length > 0 ? course?.categories[0] : ''), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                (course.totalModules)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("span", null, [
                          _cache[2] || (_cache[2] = _createElementVNode("i", { class: "far fa-book-alt" }, null, -1)),
                          _createTextVNode(_toDisplayString(course.totalModules) + " " + _toDisplayString(_ctx.$t('course.modules')), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("h3", _hoisted_13, [
                  _createVNode(_component_router_link, {
                    to: `/course-details/${course.courseId}`
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(course.detail?.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  (!_ctx.readMoreActivated[_ctx.index])
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(course.detail?.description.slice(0, 200)), 1))
                    : _createCommentVNode("", true),
                  (!_ctx.readMoreActivated[_ctx.index] && course.detail?.description.length > 200)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: "link-primary c-pointer",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(()=> _ctx.activateReadMore(_ctx.index), ["self"]))
                      }, " Read more... "))
                    : _createCommentVNode("", true),
                  (_ctx.readMoreActivated[_ctx.index])
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(course.detail?.description), 1))
                    : _createCommentVNode("", true),
                  (_ctx.readMoreActivated[_ctx.index])
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 3,
                        class: "link-primary c-pointer",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(()=> _ctx.activateReadMore(_ctx.index), ["self"]))
                      }, " Read less... "))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("img", {
                      src: course.userProfilePicture ?? require('@/assets/img/course/teacher/teacher.jpg'),
                      alt: ""
                    }, null, 8, _hoisted_19)
                  ]),
                  _createElementVNode("h6", null, [
                    _createVNode(_component_router_link, {
                      to: `/instructor-details?uid=${course.teacherId}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(course.teacherName), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("span", null, _toDisplayString(course.isFree ? 'Free' : `$${course.priceUSD}`), 1)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_router_link, {
                    to: `/course-details/${course.courseId}`,
                    class: "link-btn"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('actions.knowMore')) + " ", 1),
                      _cache[3] || (_cache[3] = _createElementVNode("i", { class: "far fa-arrow-right" }, null, -1)),
                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "far fa-arrow-right" }, null, -1))
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ])
          ])
        ])
      ])
    ]))
  }), 128))
}